.media-modal{
    width:350px!important;
    padding: 15px!important;
 }
 
 .modal-header{
     margin-bottom: 20px!important;
 }
 .modal-close svg{
     font-size: 1.3rem!important;
 }
 
 .modal-header h2{
     font-size: 1.3rem!important;
     font-weight: 500!important;
 }
 
 .user-dp{
     height: 82px;
     width: 82px;
     border-radius: 50%;
     object-fit: cover;
 }
 .user-dp-2{
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
}
 .user{
     height: 150px;
     width: 150px;
     border-radius: 50%;
     object-fit: cover;
 }
 .user-image{
     display: flex;
     padding: 16px 19px;
 }
 .user-image2{
    display: flex;
    padding:10px;
 }
 .user-data{
     display: flex;
     flex-basis: auto;
     flex-direction: column;
     flex-grow: 1;
     justify-content: center;
     min-width: 0;
 }
 .user-data2{
    display: flex;
    flex-basis: auto;
    flex-direction: column;
   
    justify-content: center;
    min-width: 0;
 }
 .crossicon{
    display: flex;
    align-items: center;
    margin: 5px;
    padding-bottom: 6px;
 }
 .about-user{
   
     align-items: center;
     min-height: 20px;
     font-size: 13px;
     line-height: 20px;
     
 }
 .user-name{
   
     overflow: hidden;
     font-size: 17px;
     font-weight: 500;
     line-height: 21px;
     margin-bottom: 5px;
     overflow-wrap: break-word;
 }
 .user-profile-section{
     display: flex;
     cursor: pointer;
     justify-content: center;
 }
 .user-profile-section2{
    display: flex;
    cursor: pointer;
}
.selectbutton{
    display: flex;
    font-size: 26px;
    position: absolute;
    left: 160px;
    top: 570px;
    align-items: center;
    color: #1a25d5;
    cursor: pointer;
}
 .content-1{
 padding: 14px;
 }
 .content{
     padding: 14px;
 }
 
 .ab-1{
     display:flex;
     line-height: 1.2;
     font-size: 0.875rem;
     font-weight: normal;
     margin-bottom: 15px;
 }
 .ab{
 padding-top: 10px;
 font-size: 15px;
 }
 .security-content{
     padding-top: 10px;
     font-size: 1.1rem;
     padding-bottom: 15px;
 }
 .security-text{
    color: #667781;
 }
 .security{
     position: relative;
     display: flex;
     flex: none;
     align-items: center;
     height: 60px;
    cursor: pointer;
 }
 .Security-types{
     margin-top:5px;
     margin-bottom: 5px;
 }
 .Security-span{
     display: inline-flex;
     align-items: center;
 }
 .security-icon{
     display: flex;
     flex: none;
     justify-content: center;
     width: 74px;
     color:#8696a0;
 }
 .Security-icon{
     padding-right:15px;
 }
 .border-top{
     padding-right: 50px;
     border-top: 1px solid rgba(134,150,160,0.15)!important;
     box-sizing: border-box;
     display: flex;
     flex: 1 1 auto;
     align-items: center;
     height: 100%;
     overflow: hidden;
 }
 .border1{
    border-top: 1px solid rgba(134,150,160,0.15)!important;
    box-sizing: border-box;
    align-items: center;
    height: 100%;
    overflow: hidden;
 }
 .sec-text{
     font-size: 1.0625rem;
     line-height: 1.2941;
 }
 .logout-img{
     width:28%;
     margin: auto;
     color:#8696a0;
 }
 .security:hover{
     background-color: #f2f2f2;
 }
 .user-profile-section:hover{
     background-color: #f2f2f2;
 }
 
 .Security-logo{
    display: flex;
    justify-content: center;
 }
 .inputfeild{
    margin: 15px!important;
 }
 .user-name-1{
   font-size: 13px;
   overflow: hidden;
   font-weight: 500;
   line-height: 21px;
   margin-bottom: 5px;
   overflow-wrap: break-word;
 }
.user-name2{
    font-size: 10px;
    overflow: hidden;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 5px;
    overflow-wrap: break-word;
}
 .about-user-1{
    font-size:11px;
    align-items: center;
    min-height: 20px;
    line-height: 10px;
 }
 .user-dp-1{
    height: 62px;
    width: 62px;
    border-radius: 100%;
    object-fit: cover;
 }




 .media-modal {
    position: absolute;
    right:0px;
    top:0px;
    bottom:0px;
    background: #fff;
    border-radius: 10px;
    z-index: 9;
    padding: 6px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    width: 750px;
    transform: translateX(500px);
    transition: all .5s ease;
}
.media-modal.open{
    transform: translateX(0px);
    transition: all .5s ease;
}
.modal-header {
    position: relative;
    margin-bottom: 50px;
}
.modal-header h2 {
    font-weight: 600;
    font-size: 2rem;
}
.modal-close {
    position: absolute;
    right: 0px;
    top: 0px;
}
.modal-close svg{
    font-size: 1.5rem;
    cursor: pointer;
}
.login-btn {
    background-color: #7380ec;
    border: 0;
    padding:5px 15px;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    border-radius: 5px;
}
.login-btn:hover{
    background-color: #26394e;
        cursor: pointer;
        color: white;
        transition: 0.5s;
}
.form-divider{
    position: relative;
    width: 100%;
    margin: 1rem 0;
    text-align: center;
    font-size: 1rem;
}
.form-divider::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 0.1rem);
    height: 2px;
    width: 100%;
    background-color: #e2e2e2;
}
.form-divider h3 {
    position: relative;
    background-color: #ffffff;
    padding: 0 1rem;
    z-index: 1;
    display: inline-block;
    font-size: 1rem;
}
.display-none{
    display: none;
}
.display-block {
    display: block;
}
.chatname{
   color:#0086ff;
   padding-left: 20px;
}
.chatfooter{
   
    border-top: 1px solid #eef2f4;
    width: 100%;
    padding: 10px 10px 10px 30px;
  
    background-color: #fff;
    position: sticky;
    bottom: 0;
    left: 0;
}

.videocontent{
    border-radius: 50px;
    padding: 20px;
}

/* .media-content{
    display: flex;
}

.media-button{
    height: 30px;
    display: flex;
  
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
    font-size: 0.875rem;
    padding-top: 4px;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    border: 0;
    
}

.media-button:active{
color:#0086ff;
border-bottom: 4px solid #0086ff;
} */

/* .tabs-container {
   
  } */
  
  .tabs {
    display: flex;
   
  }
  
  .tab {
    padding: 10px;
    cursor: pointer;
    height: 30px;
    display: flex;
  
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
    font-size: 0.875rem;
    padding-top: 4px;
    outline: none;
    
    cursor: pointer;
    background: none;
    border: 0;
    
  }
  
  .tab.active {
    border-bottom: 2px solid #0086ff; /* Apply underline or any active state style */
    font-weight: bold; /* You can change other styles to indicate the active state */
    color:#0086ff;
  }
  
  .tab-content {
    padding: 10px;
   
  }
  .mediagrp{
    display: flex;
    flex-wrap: wrap;
  }
  .Mediaimg{
    margin: 3px;
    padding: 5px;
    height: 94px;
    width: 94px;
    border-radius: 20px;
  }