form.create-user-form{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  label.create-user-form-labelBox{
    min-width: 150px;
    padding: 6px 1px;
  }
  input.create-user-form-inputBox{
    border-width: 1px;
    width: 96%;
    border-radius: 5px;
    padding: 3px 10px;
    font-size: 17px;
  }
  button.btnForm{
    background-color: rgb(110, 110, 247);
    color: white;
    width: 100px;
  }
  button.btnForm:hover{
    border: 5px rgb(176, 176, 227) solid;
    color: white;
    background-color: blue;
  }
  p.errorMsg{
    color: red;
  }



  select {
    display: block;
    width: 90%;
    margin-top: 30px;
    padding: 0.6rem 2rem 0.6rem 1rem;
    -moz-padding-start: calc(1rem - 3px);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.7;
    color: #959ca9;
    background-color: #fefefe;
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 8px 6px;
    border: 1px solid rgba(8, 60, 130, 0.07);
    border-radius: 0.4rem;
    box-shadow: 0 0 1.25rem rgba(30, 34, 40, 0.04);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }