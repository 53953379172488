.media-modal {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 9;
    padding: 50px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    width: 500px;
    transform: translateX(500px);
    transition: all .5s ease;
}
.media-modal.open{
    transform: translateX(0px);
    transition: all .5s ease;
}
.modal-header {
    position: relative;
    margin-bottom: 50px;
}
.modal-header h2 {
    font-weight: 600;
    font-size: 2rem;
}
.media-close {
    position: absolute;
    right: 0;
    top: 0;
}
.media-close svg{
    font-size: 2rem;
    cursor: pointer;
}