.setting-modal{
   width:350px!important;
   padding: 40px!important;
}

.setting-header{
    margin-bottom: 20px!important;
}
.setting-close svg{
    font-size: 1.3rem!important;
}

.setting-header h2{
    font-size: 1.3rem!important;
    font-weight: 500!important;
}

.user-dp{
    height: 82px;
    width: 82px;
    border-radius: 50%;
    object-fit: cover;
}
.user{
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit: cover;
}
.user-image{
    display: flex;
    padding: 16px 19px;
}
.user-data{
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-width: 0;
}
.about-user{
  
    align-items: center;
    min-height: 20px;
    font-size: 13px;
    line-height: 20px;
    
}
.user-name{
  
    overflow: hidden;
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 5px;
    overflow-wrap: break-word;
}
.user-profile-section{
    display: flex;
    cursor: pointer;
    justify-content: center;
}
.user-profile-section{
    display: flex;
    cursor: pointer;
    justify-content: center;
}
.content-1{
padding: 14px;
}
.content{
    padding: 14px;
}

.ab-1{
    display:flex;
    line-height: 1.2;
    font-size: 0.875rem;
    font-weight: normal;
    margin-bottom: 15px;
}
.ab{
padding-top: 10px;
font-size: 15px;
}
.security-content{
    padding-top: 10px;
    font-size: 1.1rem;
    padding-bottom: 15px;
}
.security-text{
   color: #667781;
}
.security{
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    height: 60px;
   cursor: pointer;
}
.Security-types{
    margin-top:5px;
    margin-bottom: 5px;
}
.Security-span{
    display: inline-flex;
    align-items: center;
}
.security-icon{
    display: flex;
    flex: none;
    justify-content: center;
    width: 74px;
    color:#8696a0;
}
.Security-icon{
    padding-right:15px;
}
.border-top{
    padding-right: 50px;
    border-top: 1px solid rgba(134,150,160,0.15)!important;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    height: 100%;
    overflow: hidden;
}
.sec-text{
    font-size: 1.0625rem;
    line-height: 1.2941;
}
.logout-img{
    width:28%;
    margin: auto;
    color:#8696a0;
}
.security:hover{
    background-color: #f2f2f2;
}
.user-profile-section:hover{
    background-color: #f2f2f2;
}

.Security-logo{
   display: flex;
   justify-content: center;
}

.edit-nick-name
{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.add-members{
    cursor: pointer;
    margin: 30px;

}


.user-profile-section-forward{
    display: flex;
    cursor: pointer;
    justify-content: center;
    margin-left: 45px;
}