.forward-content{
    position: absolute;
    top: 50%;
    overflow-y: scroll;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 85%;
    background-color: #fff;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
   
}

.recent{
    font-size: 15px;
    padding: 20px;
    color:#0086ff;
}

/* .checkbox-btn{
    display: flex;
} */

.check-btn{
    top:70px!important;
    right: 22px!important;
    padding: 0px 0px 10px 30px!important;
    position: relative;
    /* overflow: scroll!important; */
}
.Popup{
    padding-left: 24px;
    padding-right: 108px;
    display: flex;
    height: 62px;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    /* background-color: gainsboro; */
   
}
.Forward-selected-name{
    font-size: 1rem;
}

.Person-name{
    display: inline-block;
    text-indent: 2px;
}

.pp{
    display: flex;
    border-top: 1px solid var(--border-color);
    width: 100%;
    padding: 10px 20px;
    align-items: center;
    background-color: var(--theme-bg-color);
    position: sticky;
    bottom: 0px;
    left: 0;

}

.sendmsg{
    position: absolute;
    right: 8px;
   
}

.circlearound{
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    background-color: #0086ff;
    height: 46px;
    width: 46px;
    color:#fff;
    align-items: center;
    justify-content: center;
}

.modal-header-1{
    position: relative;
    margin-bottom: 20px!important;
    padding: 20px 20px 0px;
}
.modal-close-1{
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px 20px 0px;
    cursor: pointer;
}