$primary-color: #7380ec;
$secondary-color: #26394e;
$color-primary: #7380ec;
$color-danger: #ff7782;
$color-success: #41f1b6;
$color-warning: #ffbb55;
$color-info-dark: #7d8da1;
$color-info-light: #dce1eb;
$color-dak: #363949;
$color-light: rgba(132, 139, 200, .2);
$color-primary-varient: #111e88;
$color-dark-varient: #677483;
$color-background: #f6f6f9;

$card-border-radius: 2rem;
$border-radius-1: 0.4rem;
$border-radius-2: 0.8rem;
$border-radius-3: 1.2rem;

$card-padding: 1.8rem;
$padding-1: 1.2rem;

$box-shadow: 0 2rem 3rem var(--color-light);