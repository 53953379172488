
  .excel-viewer {
    margin-top: 20px;
  }
  
  .excel-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .excel-table th,
  .excel-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .excel-table th {
    background-color: #f2f2f2;
  }

  .document-viewer {
    margin: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  